const photos = [
    require('../Components/Home/images/tworesdi_5.jpg'),
    require('../Components/Home/images/tworesid_2.jpg'),
    require('../Components/Home/images/tworesid_3.jpg'),
    require('../Components/Home/images/tworesid_4.jpg'),
    require('../Components/Home/images/tworesid_6.jpg'),
    require('../Components/Home/images/tworesid_7.jpg'),
    require('../Components/Home/images/tworesid_8.jpg'),
    require('../Components/Home/images/tworesidnces_1.jpg'),
];

const photosAqua = [
    require('../Components/Home/images/aquavista_1.jpg'),
    require('../Components/Home/images/aquavista2.jpg'),
    require('../Components/Home/images/aquavista3.jpg'),
    require('../Components/Home/images/aquavista4.jpg'),

];

const photosskyline = [
    require('../Components/Home/images/project3.jpg'),
    require('../Components/Home/images/project3_1.jpg'),
    require('../Components/Home/images/project3_2.jpg'),
    require('../Components/Home/images/project3_3.jpg'),
    require('../Components/Home/images/project3_4.jpg'),
    require('../Components/Home/images/project3_5.jpg'),
];

const photoskhouse = [
    require('../Components/Home/images/khouse1.jpg'),
    require('../Components/Home/images/khouse2.jpg'),
    require('../Components/Home/images/khouse3.jpg'),
    require('../Components/Home/images/khouse4.jpg'),
    require('../Components/Home/images/khouse5.jpg'),
    require('../Components/Home/images/khouse6.jpg'),
];
const photosonyx = [
    require('../Components/Home/images/onyx1.jpg'),
    require('../Components/Home/images/onyx2.jpg'),
    require('../Components/Home/images/onyx3.jpg'),
    require('../Components/Home/images/onyx4.jpg'),
    require('../Components/Home/images/onyx5.jpg'),
    require('../Components/Home/images/onyx6.jpg'), 
    require('../Components/Home/images/project12_3.jpg'),
    require('../Components/Home/images/project12_4.jpg'),
    require('../Components/Home/images/project12_5.jpg'),
];
const photoselite= [
    require('../Components/Home/images/elite1.jpeg'),
    require('../Components/Home/images/elite2.jpeg'),
    require('../Components/Home/images/elite3.jpeg'),
    require('../Components/Home/images/elite4.jpeg'),
    require('../Components/Home/images/elite5.jpeg'),
  
];
const photoswood= [
    require('../Components/Home/images/wood1.jpg'),
    require('../Components/Home/images/wood2.jpg'),
    require('../Components/Home/images/wood3.jpg'),
    require('../Components/Home/images/wood4.jpg'),
    require('../Components/Home/images/wood5.jpg'),
    require('../Components/Home/images/wood56.jpg'),
];
const photospenthouse= [
    require('../Components/Home/images/penthouse1.jpg'),
    require('../Components/Home/images/penthouse2.jpg'),
    require('../Components/Home/images/penthouse3.jpg'),
    require('../Components/Home/images/penthouse4.jpg'),
    require('../Components/Home/images/penthouse5.jpg'),
    require('../Components/Home/images/penthouse6.jpg'),
];
const photosharmony= [
    require('../Components/Home/images/project9.jpg'),
    require('../Components/Home/images/project9_1.jpg'),
    require('../Components/Home/images/project9_2.jpg'),
    require('../Components/Home/images/project9_3.jpg'),
    require('../Components/Home/images/project9_4.jpg'),
    require('../Components/Home/images/project9_5.jpg'),
    require('../Components/Home/images/project9_6.jpg'),

];
const photosfutura= [
    require('../Components/Home/images/project10.jpg'),
    require('../Components/Home/images/project10_1.jpg'),
    require('../Components/Home/images/project10_2.jpg'),
    require('../Components/Home/images/project10_3.jpg'),
    require('../Components/Home/images/project10_4.jpg'),
];
const photosvista= [
    require('../Components/Home/images/project11.jpg'),
    require('../Components/Home/images/project11_1.jpg'),
    require('../Components/Home/images/project11_2.jpg'),
    require('../Components/Home/images/project11_3.jpg'),
    require('../Components/Home/images/project11_4.jpg'),
    require('../Components/Home/images/project11_5.jpg'),
];
const photossmash= [
    require('../Components/Home/images/rnd 11.jpg'),
    require('../Components/Home/images/rnd 2.jpg'),
    require('../Components/Home/images/rnd_3.jpg'),
    require('../Components/Home/images/rnd 4.jpg'),
    require('../Components/Home/images/rnd 5.jpg'),
    require('../Components/Home/images/rnd 6.jpg'),
    require('../Components/Home/images/rnd 7.jpg'),
    require('../Components/Home/images/rnd 8.jpg'),
    require('../Components/Home/images/rnd 9.jpg'),
    require('../Components/Home/images/rnd 10.jpg'),
    require('../Components/Home/images/rnd 11.jpg'),
    require('../Components/Home/images/rnd 12.jpg'),
    require('../Components/Home/images/rnd 13.jpg'),
   
];


const dataProjects = {
    "sq": {
        "twin": {
            name: "Twin Elegance Residence",
            photos: photos,
        },
        "aqua": {
            name: "AQUA VISTA ",
            photos: photosAqua,
        },
        "skyline": {
            name: "MODERN SKYLINE ",
            photos: photosskyline,
        },
        "khouse": {
            name: "K HOUSE",
            photos: photoskhouse,
        },
        "onyx": {
            name: "ONYX",
            photos: photosonyx,
        },
        "elite": {
            name: "ELITE",
            photos: photoselite,
        },
        "wood": {
            name: "A guide to Wood",
            photos: photoswood,
        },
        "penthouse": {
            name: "PENTHOUSE",
            photos: photospenthouse,
        },
        "harmony": {
            name: "HARMONY",
            photos: photosharmony,
        },
        "futura": {
            name: "FUTURA",
            photos: photosfutura,
        },
        "vista": {
            name: "AQUA VISTA",
            photos: photosvista,
        },
        "smash": {
            name: "SMASH",
            photos: photossmash,
        }
    },
    "en": {
        "twin": {
            name: "Twin Elegance Residences",
            photos: photos,
        },
        "aqua": {
            name: "AQUA VISTA",
            photos: photosAqua,
        }, 
        "skyline": {
            name: "MODERN SKYLINE ",
            photos: photosskyline,
        },
        "khouse": {
            name: "K HOUSE ",
            photos: photoskhouse,
        },
        "onyx": {
            name: "ONYX",
            photos: photosonyx,
        },
        "elite": {
            name: "ELITE",
            photos: photoselite,
        },
        "wood": {
            name: "A guide to Wood",
            photos: photoswood,
        },
        "penthouse": {
            name: "PENTHOUSE",
            photos: photospenthouse,
        },
        "harmony": {
            name: "HARMONY",
            photos: photosharmony,
        },
        "futura": {
            name: "FUTURA",
            photos: photosfutura,
        },
        "vista": {
            name: "AQUA VISTA",
            photos: photosvista,
        },
        "smash": {
            name: "SMASH",
            photos: photossmash,
        }
    }
}


export {
    dataProjects
};
