
const language = {
    sq: {
      
        menuHeader: [
            {
                name: "Projektet",
                path: "/all"
            },
            {
                name: "Rreth Nesh",
                path: "/about"
            },
           
        ],
        buttons:[
          {name: "Kthe"},
          {name: "Vazhdo"}
         ],

         about:[
          {contact: "Kontakto."},
          {work: "Punojmë së bashku."},
          {paragraf: "U-CREATE është një kompani e themeluar në vitin 2022, për një vit funksionimi ka treguar sukses të madh në Kosovë dhe jashtë Kosovës me projekte të ndryshme. Për ne projektimi nuk është vetëm një punë, në fakt është më shumë një mënyrë jetese, ndaj ne investojmë kohën dhe idetë tona për t'u dhënë njerëzve atë që kanë nevojë. Një kompani e dedikuar për projektimin dhe realizimin e projekteve të arkitekturës. Kjo kompani gjatë vitit të parë të themelimit arriti të projektoj e  realizoj 58 projekte, prej të cilave, disa prej tyre në Kosovë dhe të tjera jashtë vendit."},
          {gettoknow: "- Njihu me ne -"}, 
          {location: "- Lokacioni jonë -"},
          {contactus: "Na Kontaktoni"},
          {belgjik:"Projekte në Belgjikë, disa prej të cilave janë realizuar edhe me prodhim nga Kosova"},
          {kosov: "Projekte në Kosovë, shumica e tyre të përfunduara, të tjerat janë ende në proces"},
          {zvicerr: "Projekte në Zvicërr, të gjitha këto projekte do të prodhohen në Kosovë"},
          {norvegji: "Projekt në Norvegji, një shtëpi model për një lagje që do të ndërtohet gjatë vitit 2024"}

         ],
         

  
       
        
    },
    en: {
        
        menuHeader: [
            {name: "Projects",
            path: "/all"
        },
        {
            name: "About",
            path: "/about"
        },
       
    ],
       buttons:[
        {name: "Prev"},
        {name: "Next"}
       ],
       about:[
        {contact: "Contact."},
        {work: "Work Together."},
        {paragraf: "U-CREATE is a company founded in 2022, for one year of operation it has shown great success in Kosovo and outside Kosovo with various projects. For us, design is not just a job, it's actually more of a lifestyle, so we invest our time and ideas to give people what they need. A company dedicated to the design and realization of architecture projects. During the first year of its establishment, this company managed to design and implement 58 projects, some of them in Kosovo and others abroad."},
        {gettoknow: "- Get to know us -"}, 
        {location: "- Our Location -"},
        {contactus: "Contact Us"},
        {belgjik:"Projects in Belgium, some of which were also realized with production from Kosovo"},
          {kosov: "Projects in Kosovo, most of them completed, others are still in process"},
          {zvicerr: "Projects in Switzerland, all these projects will be produced in Kosovo"},
          {norvegji: "Project in Norway, a model house for a neighborhood to be built during 2024"}
       ],
  }
}

export default language;