import React, { useContext } from 'react';
import { Context } from '../Context/Products';
import LangFlag from './LangFlag';

const Language = () => {
    const [{ lang }, dispatch] = useContext(Context);
  
    const changeLang = () => {
      const setLang = lang === "sq" ? "en" : "sq";
      dispatch({
        type: "LANG",
        payland: { lang: setLang }
      });
      localStorage.setItem("lang", setLang)
    }

  return (
    <li className="li">
        <a className="lang" onClick={() => changeLang()}>
          <LangFlag lang={lang} />
        </a>
      </li>
  )
}

export default Language