import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Project1.scss';
import logoproject from '../../Components/Home/images/U CREATE LOGO FINALE PNG-01.png';
import { Fade } from 'react-reveal';
import language from '../../lang';
import { Context } from '../Context/Products';
import Language from '../NavBar/Language';


const Project1 = ({ data }) => {
  const [{ lang }] = useContext(Context);


  console.log("data", data);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [zoomedPhotoIndex, setZoomedPhotoIndex] = useState(null);
  const [showNextText, setShowNextText] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const navigate = useNavigate();

  const handleScroll = () => {
    if (zoomedPhotoIndex !== null) {
      // Disable scrolling when zoomed in
      document.body.style.overflow = 'hidden';
      return;
    }

    const totalContentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    const scrollPosition = window.scrollY;

    // Check if the user has reached the bottom of the page
    if (scrollPosition + windowHeight >= totalContentHeight - 1) {
      setShowNextText(true);

      // setTimeout(() => {
      //   setShowNextText(false);
      //   navigate('/all');
      // }, 1000);
    } else {
      setShowNextText(false);
    }

    setCurrentPhotoIndex(Math.floor(scrollPosition / windowHeight));
  };
  const handleNextClick = () => {
    if(!zoomedPhotoIndex){
      navigate('/all');
    }
  }

  useEffect(() => {
    if (showNextText) {
      const timer = setTimeout(() => {
        setShowNextText(false);
        handleNextClick(); // Trigger navigation
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showNextText]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleZoomIn = (index) => {
    setZoomedPhotoIndex(index);
    setTimeout(() => {
      document.body.style.overflow = 'hidden';
    }, 200);

  };

  const handleZoomOut = () => {
    setZoomedPhotoIndex(null);
    setTimeout(() => {
      document.body.style.overflow = 'initial';
    }, 200);

  };
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);


  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };


  const photoElements = data?.photos?.map((photo, index) => (
    <div key={index} className={`photo-container ${zoomedPhotoIndex === index ? 'zoomed' : ''}`}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={handleMouseLeave}>
      <img src={photo} alt='' />
      {zoomedPhotoIndex !== null && (
        <div
          className="zoom-buttons"
          style={{
            top: `${cursorPosition.y}px`,
            left: `${cursorPosition.x}px`,
            display: zoomedPhotoIndex !== null ? 'flex' : 'none',
          }}
        >
          <button onClick={handleZoomOut}>-</button>
        </div>
      )}
      {hoveredIndex === index && zoomedPhotoIndex === null && (
        <div
          className="zoom-buttons"
          style={{
            top: `${cursorPosition.y}px`,
            left: `${cursorPosition.x}px`,
            display: zoomedPhotoIndex !== null ? 'none' : 'flex',
          }}
        >
          <button onClick={() => handleZoomIn(index)}>+</button>
        </div>
      )}
    </div>
  ));


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="project1-wrapper">
      <div className="first" style={{ display: !zoomedPhotoIndex ? 'block' : 'none' }}>
        <div className="project1-header">
          <Link to="/" className="navh1">
            <img src={logoproject} alt="" />
          </Link>
          <div className='navli-lang'>
            <Link class="navli" to="/about">
              <li>{language[lang]?.menuHeader[1].name}</li>
            </Link>
            <Language />
          </div>
        </div>
        <Link className="off" to="/all">
          X
        </Link>
        <div className="project-desc">
          <h1>{data?.name}</h1>

        </div>
      </div>

      <div className="photos">{photoElements}</div>

      <div
        className="photo-number-bottom-right"
        style={{ display: !zoomedPhotoIndex ? 'flex' : 'none' }}
      >
        <span>{currentPhotoIndex + 1}</span>
        <hr className="line" />
        <span>{data?.photos?.length}</span>
      </div>

      {showNextText && zoomedPhotoIndex === null && (
        <Fade bottom>
          <div className="next-text-overlay">
            <div className="next-text" onClick={handleNextClick}>
              Next
            </div>
          </div>
        </Fade>
      )}
    </div>
  );
};

export default Project1;
