import React,{useContext} from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import language from '../../lang';
import { Context } from '../Context/Products';


const Footer = () => {
  const [{ lang}] = useContext(Context);

  return (
    <div className='right5'>
         
          <h3>{language[lang]?.about[5].contactus}</h3>
          <hr />
          <Link className='mail' target='_blank' to="https://www.gmail.com">
                    <MailOutlineIcon className='mail'/>

          </Link>

        </div>
  )
}

export default Footer