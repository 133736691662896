// Buttons.jsx

import React, {useContext} from 'react';
import './Buttons.scss';
import { Context } from '../Context/Products';
import language from '../../lang';


const Buttons = ({ handlePrev, handleNext }) => {

const [{ lang}] = useContext(Context);

  
  return (
    <div className="buttons">
      <button  className="prev-button" onClick={handlePrev}>{language[lang]?.buttons[0].name}</button>
      <button className="next-button" onClick={handleNext}>{language[lang]?.buttons[1].name}</button>
    </div>
  );
};

export default Buttons;
