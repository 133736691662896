import React, { useEffect, useContext, useState } from 'react'
import './About.scss'
import Footer from '../Footer/Footer'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { Context } from '../Context/Products';
import language from '../../lang';
import myVideo from '../About/Sequence 02_1.mp4'
import Language from '../NavBar/Language';
import pdf from '../About/2. AquaLush_SAD (2).pdf';
const About = () => {
  const [{ lang }] = useContext(Context);
  const [currentNumber, setCurrentNumber] = useState(0);
  const [currentNumber2, setCurrentNumber2] = useState(0);
  const [currentNumber3, setCurrentNumber3] = useState(0);
  const [currentNumber4, setCurrentNumber4] = useState(0);
  const definedValue = 18;
  const definedValue2 = 33;
  const definedValue3 = 4;
  const definedValue4 = 1;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentNumber < definedValue) {
        setCurrentNumber(currentNumber + 1);
      } else {
        clearInterval(interval);
      }

      if (currentNumber2 < definedValue2) {
        setCurrentNumber2(currentNumber2 + 1);
      } else {
        clearInterval(interval);
      }
      if (currentNumber3 < definedValue3) {
        setCurrentNumber3(currentNumber3 + 1);
      } else {
        clearInterval(interval);
      }
      if (currentNumber4 < definedValue4) {
        setCurrentNumber4(currentNumber4 + 1);
      } else {
        clearInterval(interval);
      }
    }, 50);
    return () => clearInterval(interval);
  }, [currentNumber, definedValue, currentNumber2, definedValue2, currentNumber3, definedValue3, currentNumber4, definedValue4]);
  return (
    <div className='about-wrapper'>
      <div className='about-first'>
        <Link className='off' to="/">X</Link>
        <Link className='language2'>
          <Language />
        </Link>
      </div>

      <div className='about-left about-content-left'>
        <div className='left1'>
          <h5>{language[lang]?.about[0].contact}</h5>
          <p>+383 44 232 505</p>
          <hr className='hr' />
        </div>
        <div className='left2'>
          <h5>{language[lang]?.about[1].work}</h5>
          <p>info@u-create.be</p>
          <hr className='hr' />
        </div>

      </div>
      <div className="about-content-right">
        <div className='about-right'>

          <div className='right1'>
            <div>
              <Fade top> <h1>U-CREATE <br />ARCHITECTURE</h1></Fade>

              <p>
                {language[lang]?.about[2].paragraf}
              </p>

            </div>

          </div>
        </div>
        <div className="about-number">
          <div className="box">
            <svg width="80px" height="80px" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" class="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet"><path fill="#141414" d="M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5H4z"></path><path fill="#FEE833" d="M12 5h12v26H12z"></path><path fill="#EE232C" d="M32 5h-8v26h8a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4z"></path></svg>

            <h1>{currentNumber}</h1>
            <p>{language[lang]?.about[6].belgjik}</p>
          </div>
          <div className="box">
          <svg height="80px" width="80px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  
	 viewBox="0 0 512 512" >
<path style={{fill:"#41479B"}} d="M473.655,423.724H38.345C17.167,423.724,0,406.557,0,385.379V126.621
	c0-21.177,17.167-38.345,38.345-38.345h435.31c21.177,0,38.345,17.167,38.345,38.345V385.38
	C512,406.557,494.833,423.724,473.655,423.724z"/>
<path style={{fill:"#FF9B55"}} d="M267.31,202.488c-5.103-0.966-11.31-7.448-9.103-11.034s-1.931-11.586-6.897-8.828
	c-4.966,2.759-8.276,6.069-12.966,7.172s-8.552,4.69-4.966,8s9.103,9.931,3.862,16.828s-3.586,2.207-9.931,5.241
	s-10.759,9.103-8.828,12.69c1.931,3.586,3.586,4.966-1.931,7.448s-8,0.828-12.414,1.931s-15.172,6.621-18.483,11.31
	c-3.31,4.69-10.483,0.276-13.517-0.276s-9.103,1.655-10.483,5.241c-1.379,3.586,1.103,6.897,6.345,8.552
	c5.241,1.655,7.448,1.931,4.966,6.345s-0.828,12.966,3.31,15.448c4.138,2.483,12.138,10.207,12.138,15.448
	s-1.103,12.966,6.621,14.069s19.586,4.414,23.724,11.586s5.241,11.31,6.069,15.724s1.655,8,4.138,12.966s4.138,13.517,1.655,15.448
	s-4.414,3.31-2.483,7.034s2.345,5.103,5.931,3.034s3.586,0.138,5.517-0.276s10.759-3.586,9.103-7.862
	c-1.655-4.276-5.241-14.069-0.138-19.172s12-5.931,16.414-9.379s11.724-4,17.379-9.517s11.724-2.207,12.69,1.655
	s2.483,11.31,6.897,11.172s5.379-0.138,6.207-5.517s7.724-17.379,10.483-15.862s5.241,0.276,5.379-2.069s5.379-4.414,7.586-1.103
	c2.207,3.31,7.034,5.931,6.897,1.793c-0.138-4.138-5.793-3.586-5.931-8.138s4.552-7.448,8.69-9.379s1.655-6.897,2.897-9.655
	c1.241-2.759,8-6.207,8.276-8.966s-1.655-3.862,1.241-4.552c2.897-0.69,6.483-1.931,4.414-5.103s-1.379-5.517,0.828-7.034
	s3.242-8.718,0.023-9.654c-3.22-0.935-9.035,1.766-10.489,3.531c-1.454,1.766-7.373-5.816-10.178-7.686
	c-2.804-1.869-20.875-0.727-20.459-3.842c0.416-3.115,8-14.487,4.69-16.28s-8.552-4.414-12.828-5.793s-3.31,3.31-5.655-0.414
	s-3.172-16.966-5.793-16.966s-4.552,5.517-5.655,3.448s0.414-16.414-5.655-16.69s-6.207,3.31-9.655,0.69
	C273.793,202.626,274.287,203.808,267.31,202.488z"/>
<g>
	<path style={{fill:"#F5F5F5"}} d="M229.837,124.019l-4.583,13.74l-14.484,0.113c-0.794,0.007-1.123,1.02-0.484,1.491l11.651,8.604
		l-4.369,13.81c-0.239,0.757,0.622,1.384,1.269,0.921l11.783-8.422l11.784,8.423c0.646,0.461,1.508-0.164,1.269-0.921l-4.369-13.81
		l11.651-8.604c0.639-0.471,0.31-1.485-0.484-1.491l-14.484-0.113l-4.583-13.74C231.154,123.266,230.088,123.266,229.837,124.019z"
		/>
	<path style={{fill:"#F5F5F5"}} d="M280.872,124.019l-4.583,13.74l-14.484,0.113c-0.794,0.007-1.123,1.02-0.484,1.491l11.651,8.604
		l-4.369,13.81c-0.239,0.757,0.622,1.384,1.269,0.921l11.784-8.423l11.784,8.423c0.646,0.461,1.508-0.164,1.269-0.921l-4.369-13.81
		l11.651-8.604c0.639-0.471,0.309-1.485-0.484-1.491l-14.484-0.113l-4.583-13.74C282.188,123.266,281.122,123.266,280.872,124.019z"
		/>
	<path style={{fill:"#F5F5F5"}} d="M179.492,132.433l-4.583,13.74l-14.484,0.113c-0.794,0.007-1.123,1.02-0.484,1.491l11.651,8.604
		l-4.369,13.81c-0.239,0.757,0.622,1.384,1.269,0.921l11.784-8.423l11.784,8.423c0.646,0.461,1.508-0.164,1.269-0.921l-4.369-13.81
		l11.651-8.604c0.639-0.471,0.31-1.485-0.484-1.491l-14.484-0.113l-4.583-13.74C180.809,131.68,179.743,131.68,179.492,132.433z"/>
	<path style={{fill:"#F5F5F5"}} d="M131.859,149.747l-4.583,13.74l-14.484,0.113c-0.794,0.007-1.123,1.02-0.484,1.491l11.651,8.604
		l-4.369,13.81c-0.239,0.757,0.622,1.384,1.269,0.921l11.784-8.423l11.784,8.423c0.646,0.461,1.508-0.164,1.269-0.921l-4.369-13.81
		l11.651-8.604c0.639-0.471,0.31-1.485-0.484-1.491l-14.484-0.113l-4.583-13.74C133.176,148.993,132.11,148.993,131.859,149.747z"/>
	<path style={{fill:"#F5F5F5"}} d="M332.647,132.433l4.583,13.74l14.484,0.113c0.794,0.007,1.123,1.02,0.484,1.491l-11.651,8.604
		l4.369,13.81c0.239,0.757-0.622,1.384-1.269,0.921l-11.784-8.423l-11.784,8.423c-0.646,0.461-1.508-0.164-1.269-0.921l4.369-13.81
		l-11.651-8.604c-0.639-0.471-0.31-1.485,0.484-1.491l14.483-0.113l4.583-13.74C331.33,131.68,332.396,131.68,332.647,132.433z"/>
	<path style={{fill:"#F5F5F5"}} d="M380.28,149.747l4.583,13.74l14.484,0.113c0.794,0.007,1.123,1.02,0.484,1.491l-11.651,8.604
		l4.369,13.81c0.239,0.757-0.622,1.384-1.269,0.921l-11.784-8.423l-11.784,8.423c-0.646,0.461-1.508-0.164-1.269-0.921l4.369-13.81
		l-11.651-8.604c-0.639-0.471-0.309-1.485,0.484-1.491l14.484-0.113l4.583-13.74C378.963,148.993,380.029,148.993,380.28,149.747z"
		/>
</g>
</svg>
            <h1>{currentNumber2}</h1>
            <p>{language[lang]?.about[7].kosov}</p>
          </div>
          <div className="box">
          <svg className='sv' width="80px" height="80px" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="img" class="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet"><path fill="#D32D27" d="M31 27a4 4 0 0 1-4 4H9a4 4 0 0 1-4-4V9a4 4 0 0 1 4-4h18a4 4 0 0 1 4 4v18z"></path><path fill="#FFF" d="M25 16.063h-5v-5h-4v5h-5V20h5v5.063h4V20h5z"></path></svg>
            <h1>{currentNumber3}</h1>
            <p>{language[lang]?.about[8].zvicerr}</p>
          </div>
          <div className="box">
          <svg width="80px" height="80px" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"  aria-hidden="true" role="img" class="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet"><path fill="#EF2B2D" d="M10 5H4a4 4 0 0 0-4 4v6h10V5zm22 0H16v10h20V9a4 4 0 0 0-4-4zM10 31H4a4 4 0 0 1-4-4v-6h10v10zm22 0H16V21h20v6a4 4 0 0 1-4 4z"></path><path fill="#002868" d="M14.5 5h-2.944l-.025 11.5H0v3h11.525L11.5 31h3V19.5H36v-3H14.5z"></path><path fill="#EEE" d="M14.5 31H16V21h20v-1.5H14.5zM16 5h-1.5v11.5H36V15H16zm-4.5 0H10v10H0v1.5h11.5zM0 19.5V21h10v10h1.5V19.5z"></path></svg>
            <h1>{currentNumber4}</h1>
            <p>{language[lang]?.about[9].norvegji}</p>
          </div>
        </div>
        {/* <div className="about-portfolio">
         
          <a  href={pdf} download>
            <svg width="35px" height="35px" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.625 15C5.625 14.5858 5.28921 14.25 4.875 14.25C4.46079 14.25 4.125 14.5858 4.125 15H5.625ZM4.875 16H4.125H4.875ZM19.275 15C19.275 14.5858 18.9392 14.25 18.525 14.25C18.1108 14.25 17.775 14.5858 17.775 15H19.275ZM11.1086 15.5387C10.8539 15.8653 10.9121 16.3366 11.2387 16.5914C11.5653 16.8461 12.0366 16.7879 12.2914 16.4613L11.1086 15.5387ZM16.1914 11.4613C16.4461 11.1347 16.3879 10.6634 16.0613 10.4086C15.7347 10.1539 15.2634 10.2121 15.0086 10.5387L16.1914 11.4613ZM11.1086 16.4613C11.3634 16.7879 11.8347 16.8461 12.1613 16.5914C12.4879 16.3366 12.5461 15.8653 12.2914 15.5387L11.1086 16.4613ZM8.39138 10.5387C8.13662 10.2121 7.66533 10.1539 7.33873 10.4086C7.01212 10.6634 6.95387 11.1347 7.20862 11.4613L8.39138 10.5387ZM10.95 16C10.95 16.4142 11.2858 16.75 11.7 16.75C12.1142 16.75 12.45 16.4142 12.45 16H10.95ZM12.45 5C12.45 4.58579 12.1142 4.25 11.7 4.25C11.2858 4.25 10.95 4.58579 10.95 5H12.45ZM4.125 15V16H5.625V15H4.125ZM4.125 16C4.125 18.0531 5.75257 19.75 7.8 19.75V18.25C6.61657 18.25 5.625 17.2607 5.625 16H4.125ZM7.8 19.75H15.6V18.25H7.8V19.75ZM15.6 19.75C17.6474 19.75 19.275 18.0531 19.275 16H17.775C17.775 17.2607 16.7834 18.25 15.6 18.25V19.75ZM19.275 16V15H17.775V16H19.275ZM12.2914 16.4613L16.1914 11.4613L15.0086 10.5387L11.1086 15.5387L12.2914 16.4613ZM12.2914 15.5387L8.39138 10.5387L7.20862 11.4613L11.1086 16.4613L12.2914 15.5387ZM12.45 16V5H10.95V16H12.45Z" 
            fill="white" />
          </svg>
          </a>
          
          <a className='a'>

            Download Portfolio</a>
        </div> */}
        <div className='knowus'>
          <h1>{language[lang]?.about[3].gettoknow}</h1>
          <video className='video' width="1000"  height="700" controls autoPlay muted playsInline>
          <source src={myVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

        </div>
        <div className='about-location'>
          <h1> {language[lang]?.about[4].location}</h1>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3519.9619591128267!2d21.125957339993466!3d42.66538910315717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549f9ea7d1d203%3A0x286e8129a78f6b3b!2sU-create%20architecture!5e0!3m2!1sen!2s!4v1701712504723!5m2!1sen!2s" className='location' width="1000" height="550" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default About