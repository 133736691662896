import React, { useEffect } from 'react'
import './All.scss'
import Fade from 'react-reveal/Fade';
import all1 from '../Home/images/01.jpg'
import all2 from '../Home/images/project3.jpg'
import all3 from '../Home/images/03.jpg'
import all4 from '../Home/images/7_7.jpg'
import all5 from '../Home/images/project9.jpg'
import all6 from '../Home/images/project11.jpg'
import all7 from '../Home/images/c2_2.jpeg'
import all8 from '../Home/images/rnd 11.jpg'
import all9 from '../Home/images/khouse1.jpg'
import all10 from '../Home/images/project10.jpg'
import all11 from '../Home/images/aquavista_1.jpg'
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import logo from '../../Components/Home/images/U CREATE LOGO FINALE  pdf-02.png'
import { useContext } from 'react';
import { Context } from '../Context/Products';
import LangFlag from '../NavBar/LangFlag';
import language from '../../lang';
import Language from '../NavBar/Language'




const All = () => {
  const [{ lang }] = useContext(Context);
  const navigate = useNavigate();

  const gotToProject = (e) => {
    navigate(`/project/${e}`);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  return (
    <div className='all-wrapper'>

      <div className="all-navbar">
        <div className='all-img'>
           <Link onClick={() => navigate("/")}>
          <img src={logo} alt="" />
        </Link>
        </div>
       
        <div className='all-links'>
          <Link onClick={() => navigate("/about")}>{language[lang]?.menuHeader[1].name}</Link>
        
          <Language />
        </div>

      </div>
      <div className='wrapper-circle'>
        <div className='circle'></div>
      </div>
      <div className='all'>
        <div className='all-left'>
          <div className='all-box' onClick={() => gotToProject('twin')}>
            <div className='image-container'>
              <img src={all1} alt="" />
              <Fade bottom><div className='all-text'>
                <p>01</p>
                <h1>TWIN ELEGANCE </h1>
                <h3>Residences</h3>
              </div>
              </Fade>
            </div>
          </div>
          <div className='all-box' onClick={() => gotToProject('skyline')}>
            <div className='image-container'>
              <img src={all2} alt="" />
              <Fade bottom> <div className='all-text'>
                <p>03</p>
                <h1>MODERN</h1>
                <h3>Skyline</h3>
              </div></Fade>
            </div>
          </div>
          <div className='all-box' onClick={() => gotToProject('onyx')}>
            <div className='image-container'>
              <img src={all3} alt="" />
              <Fade bottom><div className='all-text'>
                <p>05</p>
                <h1>ONYX</h1>
              </div>
              </Fade>
            </div>
          </div>
          <div className='all-box' onClick={() => gotToProject('wood')}>
            <div className='image-container'>
              <img src={all4} alt="" />
              <Fade bottom> <div className='all-text'>
                <p>07</p>
                <h1>A GUIDE</h1>
                <h3>To Wood</h3>
              </div></Fade>
            </div>
          </div>
          <div className='all-box' onClick={() => gotToProject('harmony')}>
            <div className='image-container'>
              <img src={all5} alt="" />
              <Fade bottom> <div className='all-text'>
                <p>09</p>
                <h1>HARMONY</h1>
              </div></Fade>
            </div>
          </div>
          <div className='all-box' onClick={() => gotToProject('vista')}>
            <div className='image-container'>
              <img src={all6} alt="" />
              <Fade bottom> <div className='all-text'>
                <p>11</p>
                <h1>AQUA VISTA</h1>
                <h3></h3>
              </div></Fade>
            </div>
          </div>
        </div>
        <div className='all-right'>
          <div className='all-box' onClick={() => gotToProject('aqua')}>
            <div className='image-container'>
              <img src={all11} alt="" />
              <Fade bottom> <div className='all-text'>
                <p>02</p>
                <h1>AQUA VISTA </h1>
                <h3>Exclusive Villa</h3>
              </div>
              </Fade></div>
          </div>
          <div className='all-box' onClick={() => gotToProject('khouse')}>
            <div className='image-container'><img src={all9} alt="" />
              <Fade bottom> <div className='all-text'>
                <p>04</p>
                <h1>K HOUSE</h1>
              </div></Fade>
            </div>
          </div>
          <div className='all-box' onClick={() => gotToProject('elite')}>
            <div className='image-container'> <img src={all7} alt="" />
              <Fade bottom> <div className='all-text'>
                <p>06</p>
                <h1>ELITE</h1>
              </div></Fade>
            </div>
          </div>
          <div className='all-box' onClick={() => gotToProject('penthouse')}>
            <div className='image-container'>  <img src={all9} alt="" />
              <Fade bottom> <div className='all-text'>
                <p>08</p>
                <h1>PENTHOUSE</h1>

              </div></Fade></div>   </div>





          <div className='all-box' onClick={() => gotToProject('futura')}>
            <div className='image-container'>  <img src={all10} alt="" />
              <Fade bottom> <div className='all-text'>
                <p>10</p>
                <h1>FUTURA</h1>
              </div></Fade></div>




          </div>
          <div className='all-box' onClick={() => gotToProject('smash')}>
            <div className='image-container'>
              <img src={all8} alt="" />
              <Fade bottom> <div className='all-text'>
                <p>12</p>
                <h1>SMASH</h1>

              </div></Fade></div>



          </div>





        </div>

      </div>
    </div>
  )
}

export default All; 