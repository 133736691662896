import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import HomePage from "./Pages/HomePage";
import ErroPage from "./Pages/ErroPage";
import AllPage from "./Pages/AllPage";
import AboutPage from "./Pages/AboutPage";
import Project1Page from "./Pages/Project1Page";
import NavBar from "./Components/NavBar/NavBar";
import Products from "./Components/Context/Products";
import './App.css';

function App() {
  return (
    <Router>
      <Products>
          <Routes>
      <Route path="/" element={<HomePage/>} />
      <Route path='/all' element={<AllPage/>} />
      <Route path="/about" element={<AboutPage/>} />
      <Route path="/project/:slug" element={<Project1Page/>}/>
      <Route path="*" element={<ErroPage/>}/>
    </Routes>
      </Products>
  
  </Router>
  );
}

export default App;
