import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import f1 from './images/01.jpg';
import f2 from './images/project3.jpg';
import f3 from './images/03.jpg';
import a4 from './images/aquavista_1.jpg';
import a5 from './images/khouse1.jpg';
import a6 from './images/c2_2.jpeg';
import a7 from './images/wood1.jpg';
import a8 from './images/penthouse1.jpg';
import a10 from './images/project9.jpg';
import a11 from './images/project10.jpg';
import a9 from './images/project11.jpg';
import a12 from './images/rnd 11.jpg';
import NavBar from '../NavBar/NavBar';
import Buttons from '../Buttons/Buttons';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import video from '../About/Sequence 02_1.mp4'; // Import the video file
import mobileVideo from '../About/finale.mp4';
import './Home.scss';

const backgroundImages = [
  f1, a4, f2, a5, f3, a6, a7, a8, a10,
  a11, a9, a12
];

const textValues = [
  'TWIN ELEGANCE RESIDENCES',
  'AQUA VISTA INTERIOR',
  'MODERN SKYLINE',
  'K HOUSE',
  'ONYX',
  'ELITE',
  'A GUIDE TO WOOD',
  'PENTHOUSE',
  'HARMONY',
  'FUTURA',
  'AQUAVISTA EXTERIOR',
  'SMASH'
];

const projectRoutes = [
  "twin",
  "aqua",
  "skyline",
  "khouse",
  "onyx",
  "elite",
  "wood",
  "penthouse",
  "harmony",
  "futura",
  "vista",
  "smash"
];

function CircularProgressWithLabel(props) {
  const navigate = useNavigate();
  const gotToProject = (e) => {
    navigate(`/project/${projectRoutes[e]}`);
  }
 
  return (
    <Box className="circle" sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress  style={{ width: "700px", height: "700px", textAlign: 'center' }} variant="determinate" {...props} />
      <Box className='small_circle'
        sx={{
          top: 122,
          left: 125,
          bottom: 160,
          right: 150,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '68%',
          height: '68%',
          border:'1px solid white',
          borderRadius: '50%',
          zIndex: 2,
        }}
      >
        <Typography className='typo' variant="caption" component="div" color="white" sx={{ fontWeight: 'normal', display:'flex', justifyContent:'center', alignItems:'center', textAlign:'center', fontSize: '35px', fontFamily: 'Times New Roman, Times, serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
          <Link className='gotoproject'  to={`/project/${projectRoutes[props.backgroundImageIndex]}`}
            onMouseEnter={() => props.setHover(true)}
            onMouseLeave={() => props.setHover(false)}
          > {textValues[props.backgroundImageIndex]}</Link>
        </Typography>
        <div style={{ position: 'absolute', bottom: -60, right: -410, display: 'flex', alignItems: 'center' }}>
          <Typography variant="caption" component="div" color="white" sx={{ fontWeight: 'normal', fontSize: '15px', fontFamily: 'Times New Roman, Times, serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5' }}>
            {props.photoNumber}
          </Typography>
          <hr style={{ width: '50px', margin: '0 5px', borderColor: 'white' }} />
          <Typography variant="caption" component="div" color="white" sx={{ fontWeight: 'normal', fontSize: '15px', fontFamily: 'Times New Roman, Times, serif', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5' }}>
            {backgroundImages.length}
          </Typography>
        </div>
      </Box>
    </Box>
  );
}

export default function CircularWithValueLabel() {
  const [progress, setProgress] = useState(100);
  const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);
  const [photoNumber, setPhotoNumber] = useState(1);
  const [isHovered, setIsHovered] = useState(false);
  // Create a ref for the video element
  const videoRef = useRef(null);

  const handlePrev = () => {
    setBackgroundImageIndex((prevIndex) => (prevIndex - 1 + backgroundImages.length) % backgroundImages.length);
    setPhotoNumber((prevNumber) => (prevNumber - 1 + backgroundImages.length) % backgroundImages.length + 0); 
  };

  const handleNext = () => {
    setBackgroundImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    setPhotoNumber((prevNumber) => (prevNumber + 1) % backgroundImages.length + 0);
  };
  const [videoAspectRatio, setVideoAspectRatio] = useState(46 / 22.8); // Initial aspect ratio, assuming 16:9 aspect ratio

  useEffect(() => {
    const updateAspectRatio = () => {
      if (videoRef.current) {
        const { videoWidth, videoHeight } = videoRef.current;
        setVideoAspectRatio(videoWidth / videoHeight);
      }
    };

    window.addEventListener('resize', updateAspectRatio);
    updateAspectRatio(); // Call once to set initial aspect ratio

    return () => window.removeEventListener('resize', updateAspectRatio);
  }, []);

  // Calculate video width based on aspect ratio and container width
  const videoWidth = Math.min(window.innerWidth, 1920); // Limit width to 1920px or window width, whichever is smaller
  const videoHeight = videoWidth / videoAspectRatio;

  useEffect(() => {
    const handleUserGesture = () => {
      if (videoRef.current && videoRef.current.paused) {
        videoRef.current.play().catch(error => {
          console.error('Autoplay was prevented: ', error);
        });
      }
    };

    document.addEventListener('click', handleUserGesture);

    return () => {
      document.removeEventListener('click', handleUserGesture);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 0 : prev + 100));

      if (progress % 100 === 0) {
        setBackgroundImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
        setPhotoNumber((prevNumber) => (prevNumber + 1) % backgroundImages.length + 0);
      }
    }, 2500);

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  const containerStyle = {
    backgroundImage: `url(${backgroundImages[backgroundImageIndex]})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition:'transform 0.3s ease-in-out',
    transform: isHovered ? 'scale(1.1)' : 'scale(1)',
  };

  const videoStyle = {
    width: `${videoWidth}px`,
    height: '102vh',
    objectFit: 'cover',
   
    //position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    filter: 'brightness(45%)',
   
  };

  const overlayStyle = {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to right, rgba(0, 0, 0, 0.9) 0%, transparent 60%, transparent 60%, rgba(0, 0, 0, 0.9) 100%)',
  };

  return (
    <div className='home-wrapper' >
      <NavBar />
      <div style={{...containerStyle, overflow: 'hidden'}}
        className='zoom-container'
        onMouseEnter={(e) => {
          if (e.target.classList.contains('gotoproject')) {
            setIsHovered(true);
          }
        }}
        onMouseLeave={() => setIsHovered(false)}
      > 
        <div className='divi' style={overlayStyle}></div>
        <CircularProgressWithLabel
          className={`largecircle ${window.innerWidth <= 768 ? 'smallScreen' : ''}`}
          thickness={7.0}
          value={progress}
          backgroundImageIndex={backgroundImageIndex}
          photoNumber={photoNumber}
          setHover={setIsHovered}
          style={{
            width:'770px',
            height:'770px',
            color: '#353935',
            filter: 'blur(1px)',
            opacity: 0.15,
            boxShadow: [
              '6px 6px 10px -1px rgba(255, 255, 255, 0.8)',
              '-6px -6px 10px -1px rgba(255, 255, 255, 0.8)',
            ].join(', '),
            borderRadius: '50%',
          }}
        />
      </div>
      <Buttons className="btns" handlePrev={handlePrev} handleNext={handleNext} />
      <div style={{ position: 'relative' }}>

      </div>
      {/* Reference the video element using the ref */}
      <video ref={videoRef} autoPlay playsInline loop muted style={videoStyle} >
 <source src={window.innerWidth <= 1200 ? mobileVideo : video} type='video/mp4' />        Your browser does not support the video tag.
      </video>
     
    </div>
  );
}

export {
  CircularProgressWithLabel, CircularWithValueLabel, backgroundImages
};