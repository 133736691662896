import React, { useEffect } from 'react';
import './NavBar.scss';
import { Link } from 'react-router-dom';
import logo from '../Home/images/U CREATE LOGO FINALE  pdf-02.png';
import language from '../../lang';
import LangFlag from './LangFlag';
import { Context } from '../Context/Products';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Language from './Language';

const NavBar = () => {
 
  const [{ lang }] = useContext(Context);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: smooth scrolling animation
    });
  };
 
  return (
    <div className="navbar">
      <Link to="/" className="navh1" onClick={scrollToTop}>
        <img src={logo} alt="" />
      </Link>
      <div className="links">
        {language[lang]?.menuHeader.map((el) => (
          <li className="li" key={el.path}>
            <NavLink className="navli" to={el.path}>
              {el.name}
            </NavLink>
          </li>
        ))}
        <Language className="lang"/>
      </div>
    </div>
  );
};

export default NavBar;
