import React from "react";

const LangFlag = ({ lang }) => {
    if(lang === "sq"){
        return (
          "EN"
        )
    }
    return(
       "AL"
    )
}

export default LangFlag;